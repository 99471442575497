import axios from 'axios'
import { createPublicSubscriptionPlansApi } from '@r1-oas/public-subscription-plans'

const http = axios.create()

export const publicSubscriptionsPlansApi = createPublicSubscriptionPlansApi({ http })

http.interceptors.response.use(response => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return response.data
})
