import React from 'react'
import { Column, Row } from '../components/Table/types'
import { ServiceColumn } from '../components/Table/views/ServiceColumn'
import { PriceColumn } from '../components/Table/views/PriceColumn'
import { Typography } from '../components/Typography'

export const useSupplyChainServicesTable = () => {
  const supplyChainTableColumns: Column[] = [
    {
      content: <Typography.H5>Service Type</Typography.H5>,
      width: 500,
      fullWidth: true,
      className: 'py-8 px-5',
    },
    {
      content: <Typography.H5>Clothing</Typography.H5>,
      width: 200,
    },
    {
      content: <Typography.H5>Smartphones</Typography.H5>,
      width: 200,
    },
    {
      content: <Typography.H5>Computers</Typography.H5>,
      width: 200,
    },
    {
      content: <Typography.H5>Tablets</Typography.H5>,
      width: 200,
    },
  ]

  const supplyChainTableData: Array<Row[]> = [
    [
      {
        content: (
          <ServiceColumn
            service="Receive"
            items={[
              'Basic Check In',
              'Premium Check in',
              'Single Sort Handling',
              'Multiple Sort Handling',
            ]}
          />
        ),
      },
      {
        content: <PriceColumn prices={['$1.50', '$1.50', '$1.00', '$1.00']} />,
      },
      {
        content: <PriceColumn prices={['$2.00', '$3.50', '$2.00', '$2.25']} />,
      },
      {
        content: <PriceColumn prices={['$2.00', '$3.50', '$2.00', '$2.25']} />,
      },
      {
        content: <PriceColumn prices={['$2.00', '$3.50', '$2.00', '$2.25']} />,
      },
    ],
    [
      {
        content: <ServiceColumn service="Inspect" items={['Inspect/Grade', 'Test']} />,
      },
      { content: <PriceColumn prices={['$2.00', 'N/A']} /> },
      { content: <PriceColumn prices={['$1.00', '$7.50']} /> },
      { content: <PriceColumn prices={['$7.00', '$10.50']} /> },
      { content: <PriceColumn prices={['$5.00', '$7.50']} /> },
    ],
    [
      {
        content: <ServiceColumn service="Sanitize" items={['Remove Labels', 'Data Wipe']} />,
      },
      { content: <PriceColumn prices={['$0.50', 'N/A']} /> },
      { content: <PriceColumn prices={['$1.00', '$7.00']} /> },
      { content: <PriceColumn prices={['$1.00', '$10.00']} /> },
      { content: <PriceColumn prices={['$1.00', '$7.00']} /> },
    ],
    [
      {
        content: (
          <ServiceColumn
            service="Fulfill"
            items={[
              'Put Away',
              'Pick and Ship Bulk (Pallet) Shipment',
              'Pick & Ship Parcel Shipment',
            ]}
          />
        ),
      },
      { content: <PriceColumn prices={['$1.00', '$2.00', '$3.00']} /> },
      { content: <PriceColumn prices={['$1.50', '$2.00', '$5.00']} /> },
      { content: <PriceColumn prices={['$3.00', '$3.50', '$6.50']} /> },
      { content: <PriceColumn prices={['$2.50', '$2.50', '$5.50']} /> },
    ],
    [
      {
        content: (
          <ServiceColumn
            service="Repackage"
            items={[
              'Single Unit Packaing Small',
              'Single Unit Packing Medium',
              'Single Unit Packing Large',
              'Builk Packing (Gaylord)',
            ]}
          />
        ),
      },
      {
        content: <PriceColumn prices={['$3.50', '$5.00', '$7.50', '$18.50']} />,
      },
      {
        content: <PriceColumn prices={['$3.50', '$5.00', '$7.50', '$18.50']} />,
      },
      {
        content: <PriceColumn prices={['$3.50', '$5.00', '$7.50', '$18.50']} />,
      },
      {
        content: <PriceColumn prices={['$3.50', '$5.00', '$7.50', '$18.50']} />,
      },
    ],
    [{ content: <ServiceColumn service="Recycle" /> }, { content: 'Complimentary', colSpan: 4 }],
    [{ content: <ServiceColumn service="Donation" /> }, { content: 'Complimentary', colSpan: 4 }],
    [
      { content: <ServiceColumn service="Return to Vendor" /> },
      {
        content:
          'Prices vary based on vendor requirements, to estimate price please add services above based on size, category and vendor requirements.',
        colSpan: 4,
      },
    ],
  ]

  return { supplyChainTableData, supplyChainTableColumns }
}
