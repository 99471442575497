type CalculationParams = {
  weight: number
  weightUnits: string
  categoryId: number
}

type CategoryType =
  | 'cat1'
  | 'cat2'
  | 'cat3'
  | 'cat4'
  | 'cat5'
  | 'cat6'
  | 'cat7'
  | 'cat8'
  | 'cat9'
  | 'cat10'
  | 'cat11'
  | 'cat12'
  | 'cat13'
  | 'cat14'
  | 'cat15'
  | 'cat16'
  | 'cat17'

type CategoryWeightMap = {
  [key: number]: {
    [key in CategoryType]: number
  }
}

const ouncesWeightUnits = Array.from(Array(9).keys()).map(i => i + 8)
const ouncesWeightValuesCat1 = [1.79, 2.01, 2.23, 2.46, 2.68, 2.9, 3.13, 3.35, 3.58]
const ouncesWeightValuesCat2 = [2.03, 2.29, 2.54, 2.79, 3.05, 3.3, 3.55, 3.81, 4.06]
const ouncesWeightValuesCat3 = [6.47, 7.28, 8.09, 8.9, 9.71, 10.52, 11.33, 12.14, 12.95]
const ouncesWeightValuesCat4 = [12.61, 14.19, 15.76, 17.34, 18.92, 20.49, 22.07, 23.65, 25.22]
const ouncesWeightValuesCat5 = [2.09, 2.35, 2.61, 2.87, 3.13, 3.39, 3.65, 3.92, 4.18]
const ouncesWeightValuesCat6 = [12.23, 13.76, 15.29, 16.81, 18.34, 19.87, 21.4, 22.93, 24.46]
const ouncesWeightValuesCat7 = [12.75, 14.34, 15.93, 17.53, 19.12, 20.71, 22.31, 23.9, 25.49]
const ouncesWeightValuesCat8 = [1.8, 2.02, 2.25, 2.47, 2.69, 2.92, 3.14, 3.37, 3.59]
const ouncesWeightValuesCat9 = [6.84, 7.69, 8.55, 9.4, 10.26, 11.11, 11.96, 12.82, 13.67]
const ouncesWeightValuesCat10 = [9.59, 10.79, 11.98, 13.18, 14.38, 14.38, 14.38, 14.38, 14.38]
const ouncesWeightValuesCat11 = [3.46, 3.89, 4.32, 4.75, 5.19, 5.62, 6.05, 6.48, 6.91]
const ouncesWeightValuesCat12 = [1.26, 1.42, 1.58, 1.74, 1.9, 2.05, 2.21, 2.37, 2.53]
const ouncesWeightValuesCat13 = [2.4, 2.71, 3.01, 3.31, 3.61, 3.91, 4.21, 4.51, 4.81]
const ouncesWeightValuesCat14 = [1.92, 2.16, 2.4, 2.64, 2.88, 3.12, 3.36, 3.6, 3.83]
const ouncesWeightValuesCat15 = [1.43, 1.61, 1.79, 1.97, 2.15, 2.32, 2.5, 2.68, 2.86]
const ouncesWeightValuesCat16 = [1.67, 1.88, 2.09, 2.3, 2.51, 2.72, 2.93, 3.14, 3.35]
const ouncesWeightValuesCat17 = [1.44, 1.62, 1.79, 1.97, 2.15, 2.33, 2.51, 2.69, 2.87]

const ouncesWeightMap: CategoryWeightMap = ouncesWeightUnits.reduce(
  (acc, curr, index) => ({
    ...acc,
    [curr]: {
      cat1: ouncesWeightValuesCat1[index],
      cat2: ouncesWeightValuesCat2[index],
      cat3: ouncesWeightValuesCat3[index],
      cat4: ouncesWeightValuesCat4[index],
      cat5: ouncesWeightValuesCat5[index],
      cat6: ouncesWeightValuesCat6[index],
      cat7: ouncesWeightValuesCat7[index],
      cat8: ouncesWeightValuesCat8[index],
      cat9: ouncesWeightValuesCat9[index],
      cat10: ouncesWeightValuesCat10[index],
      cat11: ouncesWeightValuesCat11[index],
      cat12: ouncesWeightValuesCat12[index],
      cat13: ouncesWeightValuesCat13[index],
      cat14: ouncesWeightValuesCat14[index],
      cat15: ouncesWeightValuesCat15[index],
      cat16: ouncesWeightValuesCat16[index],
      cat17: ouncesWeightValuesCat17[index],
    },
  }),
  {},
)

const poundsWeightUnits = Array.from(Array(55).keys()).map(i => i + 1)
const poundsWeightValuesCat1 = [
  3.58, 3.58, 3.99, 4.41, 4.83, 5.55, 6.27, 7.0, 7.72, 8.45, 9.08, 9.71, 10.35, 10.98, 11.61, 12.25,
  12.88, 13.51, 14.15, 14.78, 14.9, 15.03, 15.15, 15.27, 15.4, 15.52, 15.64, 15.77, 15.89, 16.01,
  16.14, 16.26, 16.38, 16.51, 16.63, 16.75, 16.88, 17.0, 17.12, 17.24, 17.37, 17.49, 17.61, 17.74,
  17.86, 17.98, 18.11, 18.23, 18.35, 18.48, 24.02, 29.56, 35.11, 40.65, 46.19,
]
const poundsWeightValuesCat2 = [
  4.06, 4.06, 4.54, 5.01, 5.48, 6.31, 7.13, 7.95, 8.77, 9.6, 10.32, 11.04, 11.76, 12.48, 13.2,
  13.92, 14.64, 15.36, 16.08, 16.8, 16.8, 16.8, 16.8, 16.81, 16.81, 16.81, 16.82, 16.82, 16.82,
  16.83, 16.83, 16.83, 16.84, 16.84, 16.85, 16.85, 16.86, 16.86, 16.87, 16.88, 16.89, 16.89, 16.9,
  16.91, 16.93, 16.94, 16.96, 16.99, 17.02, 20.99, 27.29, 33.59, 39.89, 46.19, 52.49,
]
const poundsWeightValuesCat3 = [12.95, 12.95, 14.46, 15.97, 17.48].concat(
  Array.from(Array(50)).map(() => 17.48),
)
const poundsWeightValuesCat4 = [
  25.22, 25.22, 28.16, 31.11, 34.05, 39.16, 44.26, 49.37, 54.48, 59.59, 64.06, 68.53, 72.99, 77.46,
  81.93, 86.4, 90.87, 95.34, 99.81, 104.28, 104.29, 104.29, 104.3, 104.31, 104.32, 104.33, 104.34,
  104.35, 104.36, 104.38, 104.39, 104.4, 104.41, 104.43, 104.44, 104.46, 104.48, 104.5, 104.52,
  104.54, 104.56, 104.59, 104.62, 104.66, 104.7,
].concat(Array.from(Array(10)).map(() => 104.7))
const poundsWeightValuesCat5 = [
  4.18, 4.18, 4.66, 5.15, 5.64, 6.48, 7.33, 8.18, 9.02, 9.87, 10.61, 11.35, 12.09, 12.83, 13.57,
  14.31, 15.05, 15.79, 16.53, 17.27,
].concat(Array.from(Array(35)).map(() => 17.27))
const poundsWeightValuesCat6 = [24.46, 24.46, 27.31, 30.16, 33.02].concat(
  Array.from(Array(50)).map(() => 33.02),
)
const poundsWeightValuesCat7 = [25.49, 25.49, 28.47, 31.44].concat(
  Array.from(Array(51)).map(() => 31.44),
)
const poundsWeightValuesCat8 = Array.from(Array(55)).map(() => 3.59)
const poundsWeightValuesCat9 = [13.67, 13.67, 15.27, 16.86, 18.46].concat(
  Array.from(Array(50)).map(() => 18.46),
)
const poundsWeightValuesCat10 = Array.from(Array(55)).map(() => 14.38)
const poundsWeightValuesCat11 = [
  6.91, 6.91, 7.72, 8.53, 9.33, 10.73, 12.13, 13.53, 14.93, 16.33, 17.56, 18.78, 20.01, 21.23,
  22.46, 23.68, 24.91, 26.13, 27.36, 28.58, 28.59, 28.59, 28.59, 28.59, 28.59, 28.59, 28.59, 28.59,
  28.59, 28.6, 28.6, 28.6, 28.6, 28.6, 28.61, 28.61, 28.61, 28.61, 28.61, 28.62, 28.62, 28.62,
  28.63, 28.63, 28.64, 28.64, 28.65, 28.66, 28.68, 35.73, 37.52, 39.3, 41.09, 42.88, 44.66,
]
const poundsWeightValuesCat12 = [
  2.53, 2.53, 2.82, 3.12, 3.41, 3.93, 4.44, 4.95, 5.46, 5.98, 6.42, 6.87, 7.32, 7.77, 8.22,
].concat(Array.from(Array(40)).map(() => 8.22))
const poundsWeightValuesCat13 = [
  4.81, 4.81, 5.37, 5.93, 6.49, 7.47, 8.44, 9.41, 10.39, 11.36, 12.21, 13.07, 13.92, 14.77, 15.62,
  16.48, 17.33, 18.18, 19.03, 19.88, 19.88, 19.89, 19.89, 19.89, 19.89, 19.89, 19.89, 19.89, 19.89,
  19.89, 19.89, 19.89, 19.89, 19.9, 19.9, 19.9, 19.9, 19.9, 19.9, 19.9, 19.91, 19.91, 19.91, 19.91,
  19.92, 19.92, 19.93, 19.93, 19.94, 24.85, 26.1, 27.34, 28.58, 29.83, 31.07,
]
const poundsWeightValuesCat14 = [
  3.83, 3.83, 4.28, 4.73, 5.18, 5.95, 6.73, 7.51, 8.28, 9.06, 9.74, 10.42, 11.1, 11.78, 12.46,
  13.14, 13.82, 14.5, 15.18, 15.85, 15.86, 15.86, 15.86, 15.86, 15.86, 15.86, 15.86, 15.86, 15.86,
  15.86, 15.86, 15.86, 15.86, 15.86, 15.87, 15.87, 15.87, 15.87, 15.87, 15.87, 15.87, 15.87, 15.88,
  15.88, 15.88, 15.88, 15.89, 15.89, 15.9, 19.82, 20.81, 21.8, 22.79, 23.78, 24.77,
]
const poundsWeightValuesCat15 = [
  2.86, 2.86, 3.19, 3.53, 3.86, 4.44, 5.02, 5.6, 6.18, 6.76, 7.26, 7.77, 8.28, 8.79, 9.29, 9.8,
  10.31, 10.81, 11.32, 11.83,
].concat(Array.from(Array(35)).map(() => 11.83))
const poundsWeightValuesCat16 = [
  3.35, 3.35, 3.74, 4.13, 4.52, 5.2, 5.88, 6.55, 7.23, 7.91, 8.5, 9.1, 9.69, 10.28, 10.87, 11.47,
  12.06, 12.65, 13.25, 13.84, 13.84, 13.84, 13.84, 13.84, 13.84, 13.84, 13.84, 13.84, 13.84, 13.84,
  13.85, 13.85, 13.85, 13.85, 13.85, 13.85, 13.85, 13.85, 13.85, 13.85, 13.85, 13.85, 13.86, 13.86,
  13.86, 13.86, 13.86, 13.87, 13.87, 17.3, 22.49, 27.68, 32.87, 38.06, 43.25,
]
const poundsWeightValuesCat17 = [
  2.87, 2.87, 3.21, 3.54, 3.88, 4.46, 5.04, 5.62, 6.2, 6.78, 7.29, 7.8, 8.31, 8.82, 9.33, 9.84,
  10.34, 10.85, 11.36, 11.87, 11.87, 11.87, 11.87, 11.87, 11.87, 11.87, 11.87, 11.87, 11.87, 11.87,
  11.87, 11.87, 11.87, 11.88, 11.88, 11.88, 11.88, 11.88, 11.88, 11.88, 11.88, 11.88, 11.88, 11.88,
  11.88, 11.89, 11.89, 11.89, 11.89, 14.84, 15.58, 16.32, 17.06, 17.81, 18.55,
]

const poundsWeightMap: CategoryWeightMap = poundsWeightUnits.reduce(
  (acc, curr, index) => ({
    ...acc,
    [curr]: {
      cat1: poundsWeightValuesCat1[index],
      cat2: poundsWeightValuesCat2[index],
      cat3: poundsWeightValuesCat3[index],
      cat4: poundsWeightValuesCat4[index],
      cat5: poundsWeightValuesCat5[index],
      cat6: poundsWeightValuesCat6[index],
      cat7: poundsWeightValuesCat7[index],
      cat8: poundsWeightValuesCat8[index],
      cat9: poundsWeightValuesCat9[index],
      cat10: poundsWeightValuesCat10[index],
      cat11: poundsWeightValuesCat11[index],
      cat12: poundsWeightValuesCat12[index],
      cat13: poundsWeightValuesCat13[index],
      cat14: poundsWeightValuesCat14[index],
      cat15: poundsWeightValuesCat15[index],
      cat16: poundsWeightValuesCat16[index],
      cat17: poundsWeightValuesCat17[index],
    },
  }),
  {},
)

export const calculateValueAddedServicesValue = ({
  weight,
  weightUnits,
  categoryId,
}: CalculationParams): number => {
  const automotiveBabyBooksClothing = categoryId >= 2 && categoryId <= 50 // Automotive, Baby, Books, Clothing category
  const camerasAndCarAudio = categoryId >= 51 && categoryId <= 70 // Electronics, Cameras, Car Audio
  const computerAccessories =
    (categoryId >= 85 && categoryId <= 119) || [920, 921].includes(categoryId) // Computer Accessories
  const cellularPhones = categoryId >= 71 && categoryId <= 84 // Cellular Phones
  const computers = categoryId >= 120 && categoryId <= 124 // Computers
  const specificElectronics = [125, 142, 413].includes(categoryId) // specific Electronics
  const dronesAndGps = categoryId >= 126 && categoryId <= 131 // Drones, GPS
  const headphones = categoryId >= 132 && categoryId <= 136 // Headphones
  const homeAudioVideoAndPriners = categoryId >= 137 && categoryId <= 164 // Home Audio, Video, Printing
  const tablets = categoryId >= 165 && categoryId <= 169 // Tablets
  const tabletAccessories = categoryId >= 170 && categoryId <= 174 // Tablet Accessories
  const videoGames = categoryId >= 179 && categoryId <= 188 // Video games
  const videoGamesAccessories = categoryId >= 189 && categoryId <= 206 // Video games accessories
  const wearableTech = categoryId >= 207 && categoryId <= 217 // Wearable tech
  const homeAppliances = categoryId >= 247 && categoryId <= 271 // Home appliances
  const bedding = categoryId >= 272 && categoryId <= 278 // Bedding
  const homeHeatingCooling = categoryId >= 279 && categoryId <= 285 // Home heating & cooling
  const specificHome = [295, 297, 300].includes(categoryId) // specific Home
  const homeImprovement = categoryId >= 301 && categoryId <= 313 // Home improvement
  const jewelry = categoryId >= 319 && categoryId <= 328 // Jewelry
  const musicalInstruments = categoryId >= 340 && categoryId <= 343 // Musical instruments
  const patioGarden = categoryId >= 360 && categoryId <= 374 // Patio & garden
  const sporting = categoryId >= 376 && categoryId <= 394 // Sporting
  const toys = categoryId >= 395 && categoryId <= 412 // Toys

  let category: CategoryType = 'cat1'

  if (automotiveBabyBooksClothing) {
    category = 'cat1'
  } else if (camerasAndCarAudio || computerAccessories || specificElectronics) {
    category = 'cat2'
  } else if (cellularPhones) {
    category = 'cat3'
  } else if (computers) {
    category = 'cat4'
  } else if (dronesAndGps || homeAudioVideoAndPriners) {
    category = 'cat5'
  } else if (headphones) {
    category = 'cat6'
  } else if (tablets) {
    category = 'cat7'
  } else if (tabletAccessories || videoGamesAccessories) {
    category = 'cat8'
  } else if (videoGames) {
    category = 'cat9'
  } else if (wearableTech) {
    category = 'cat10'
  } else if (homeAppliances) {
    category = 'cat11'
  } else if (bedding) {
    category = 'cat12'
  } else if (homeHeatingCooling) {
    category = 'cat13'
  } else if (specificHome || homeImprovement) {
    category = 'cat14'
  } else if (jewelry || musicalInstruments || sporting) {
    category = 'cat15'
  } else if (patioGarden) {
    category = 'cat16'
  } else if (toys) {
    category = 'cat17'
  }

  if (weightUnits === 'ounces') {
    let weightValue: number = 8

    if (weight > 0 && weight <= 8) {
      weightValue = 8
    } else if (weight >= 16) {
      weightValue = 16
    } else {
      weightValue = Math.ceil(weight) || 8
    }

    return ouncesWeightMap[weightValue][category]
  }

  if (weightUnits === 'pounds') {
    let weightValue: number = 1

    if (weight > 0 && weight <= 1) {
      weightValue = 1
    } else if (weight >= 55) {
      weightValue = 55
    } else {
      weightValue = Math.ceil(weight) || 1
    }

    return poundsWeightMap[weightValue][category]
  }

  return 0
}
