import React from 'react'

export const ServiceColumn = ({ service, items = [] }: { service: string; items?: string[] }) => {
  return (
    <div className="grid grid-cols-2 gap-5 font-uniform-rnd max-sm:grid-cols-1">
      <p className="body-text font-bold max-lg:w-auto">{service}</p>
      <ul className="flex w-72 flex-col gap-4 max-lg:w-auto">
        {items.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  )
}
