import React from 'react'
import cn from 'classnames'
import { twMerge } from 'tailwind-merge'
import { Column, Row } from './types'

type Props = {
  columns: Column[]
  rows: Row[][]
  className?: string
  showHeaderShadow?: boolean
  mostPopularColumnIndex?: number
}

export const Table = ({
  rows,
  columns,
  className,
  mostPopularColumnIndex,
  showHeaderShadow = true,
}: Props) => {
  return (
    <table className={twMerge('custom-table w-full', className)}>
      <thead
        className={cn('relative', {
          'z-10 shadow-table-header': showHeaderShadow,
        })}
      >
        <tr>
          {columns.map((col, colIndex) => {
            const isFirstCol = colIndex === 0
            const isMostPopularColumn = colIndex === mostPopularColumnIndex

            return (
              <th
                // eslint-disable-next-line react/no-array-index-key
                key={colIndex}
                className={twMerge(
                  `px-5 py-12 w-[${col.width}px] align bg-white max-sm:px-2`,
                  cn({
                    'relative bg-[#EAF2FB]': isMostPopularColumn,
                    'text-left': isFirstCol,
                    'w-full': col.fullWidth,
                  }),
                  col.className,
                )}
              >
                {isMostPopularColumn && (
                  <div className="absolute -top-4 left-1/2 -translate-x-1/2 rounded-sm bg-[#617F32] px-2 py-1 text-white max-sm:hidden">
                    Most popular
                  </div>
                )}
                {col.content}
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={rowIndex}>
              {row.map((col, colIndex) => {
                const isMostPopularColumn = colIndex === mostPopularColumnIndex
                const isHiddenTd = col.content === null
                const isPreviousColNull = rows[rowIndex][colIndex - 1]?.content === null
                const isNode = col.content !== null && typeof col.content === 'object'
                const isFirstCol = colIndex === 0
                const isRoundedLeftBottom =
                  ((!rows[rowIndex + 1] || rows[rowIndex + 1]?.[0].content === null) &&
                    isFirstCol) ||
                  rows[rowIndex][colIndex - 1]?.content === null

                return (
                  <td
                    // eslint-disable-next-line react/no-array-index-key
                    key={colIndex}
                    colSpan={col.colSpan}
                    rowSpan={col.rowSpan}
                    className={twMerge(
                      'body-text bg-white p-5 text-left text-nav-item-text max-sm:px-2',
                      cn({
                        'opacity-0': isHiddenTd,
                        'relative bg-[#EAF2FB]': isMostPopularColumn,
                        'cell-center': isNode,
                        'rounded-bl-xl': isRoundedLeftBottom,
                        'border-l': isPreviousColNull,
                      }),
                    )}
                  >
                    {col.content}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
