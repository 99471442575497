export const weightUnits = [
  { value: 'pounds', title: 'pounds' },
  { value: 'ounces', title: 'ounces' },
]

export const categories = [
  { id: 1, value: 'Adult' },
  { id: 2, value: 'Automotive' },
  { id: 3, value: 'Automotive Accessories', pId: 2 },
  { id: 4, value: 'Automotive Parts', pId: 2 },
  { id: 5, value: 'Automotive Tools', pId: 2 },
  { id: 6, value: 'Hand', pId: 5 },
  { id: 7, value: 'Power', pId: 5 },
  { id: 8, value: 'Tires', pId: 2 },
  { id: 9, value: 'Baby' },
  { id: 10, value: 'Baby Food & Formula', pId: 9 },
  { id: 11, value: 'Baby Monitors', pId: 9 },
  { id: 12, value: 'Bedding & Décor', pId: 9 },
  { id: 13, value: 'Car Seats', pId: 9 },
  { id: 14, value: 'Diapers & Wipes', pId: 9 },
  { id: 15, value: 'Health & Safety', pId: 9 },
  { id: 16, value: 'Nursing & Feeding Supplies', pId: 9 },
  { id: 17, value: 'Strollers', pId: 9 },
  { id: 18, value: 'Walkers, Swings & Bouncers', pId: 9 },
  { id: 19, value: 'Books' },
  { id: 20, value: 'Clothing, Shoes & Accessories' },
  { id: 21, value: 'Backpacks, Bags, Wallets & Accessories', pId: 20 },
  { id: 22, value: 'Clothing' },
  { id: 23, value: 'Babies', pId: 22 },
  { id: 24, value: 'Boys', pId: 22 },
  { id: 25, value: 'Underwear', pId: 24 },
  { id: 26, value: 'Girls', pId: 22 },
  { id: 27, value: 'Underwear', pId: 26 },
  { id: 28, value: 'Mens', pId: 22 },
  { id: 29, value: 'Dress Shirts', pId: 28 },
  { id: 30, value: 'Jackets & Outerwear', pId: 28 },
  { id: 31, value: 'Jeans, Pants & Shorts', pId: 28 },
  { id: 32, value: 'Sleepwear & Robes', pId: 28 },
  { id: 33, value: 'Suits & Sportcoats', pId: 28 },
  { id: 34, value: 'Swimwear', pId: 28 },
  { id: 35, value: 'T-Shirts, Polos, Sweaters', pId: 28 },
  { id: 36, value: 'Underwear & Socks', pId: 28 },
  { id: 37, value: 'Womens', pId: 22 },
  { id: 38, value: 'Dresses & Skirts', pId: 37 },
  { id: 39, value: 'Jackets & Outerwear', pId: 37 },
  { id: 40, value: 'Jeans, Pants, Legging & Shorts', pId: 37 },
  { id: 41, value: 'Shirts & Blouses', pId: 37 },
  { id: 42, value: 'Swimwear', pId: 37 },
  { id: 43, value: 'T-Shirts, Polos, Sweaters & Cardigans', pId: 37 },
  { id: 44, value: 'Underwear, Intimates, Sleepwear & Socks', pId: 37 },
  { id: 45, value: 'Shoes', pId: 20 },
  { id: 46, value: 'Babies', pId: 45 },
  { id: 47, value: 'Boys', pId: 45 },
  { id: 48, value: 'Girls', pId: 45 },
  { id: 49, value: 'Mens', pId: 45 },
  { id: 50, value: 'Womens', pId: 45 },
  { id: 51, value: 'Electronics' },
  { id: 52, value: 'Batteries', pId: 51 },
  { id: 53, value: 'Cameras', pId: 51 },
  { id: 54, value: 'Accessories', pId: 53 },
  { id: 55, value: 'Camcorders', pId: 53 },
  { id: 56, value: 'Action Camcorders', pId: 55 },
  { id: 57, value: 'Digital Camcorders', pId: 55 },
  { id: 58, value: 'DSLR', pId: 53 },
  { id: 59, value: 'Lenses', pId: 53 },
  { id: 60, value: 'Point & Shoot', pId: 53 },
  { id: 61, value: 'Car Audio, Video & Electronics', pId: 51 },
  { id: 62, value: 'Accessories', pId: 61 },
  { id: 63, value: 'Alarms', pId: 61 },
  { id: 64, value: 'Amplifiers', pId: 61 },
  { id: 65, value: 'Back up & Dashboard Cameras', pId: 61 },
  { id: 66, value: 'CB Radios', pId: 61 },
  { id: 67, value: 'Handsfree', pId: 61 },
  { id: 68, value: 'Speakers', pId: 61 },
  { id: 69, value: 'Stereos', pId: 61 },
  { id: 70, value: 'Video', pId: 61 },
  { id: 71, value: 'Cellular Phones', pId: 51 },
  { id: 72, value: 'Accessories', pId: 71 },
  { id: 73, value: 'Cases', pId: 72 },
  { id: 74, value: 'Other', pId: 72 },
  { id: 75, value: 'Basic Mobile Phones (Non-Smart)', pId: 71 },
  { id: 76, value: 'Postpaid', pId: 75 },
  { id: 77, value: 'Prepaid', pId: 75 },
  { id: 78, value: 'Mobile Hotspot', pId: 71 },
  { id: 79, value: 'Smart Phones', pId: 71 },
  { id: 80, value: 'Apple iPhones', pId: 79 },
  { id: 81, value: 'LG', pId: 79 },
  { id: 82, value: 'Other', pId: 79 },
  { id: 83, value: 'Samsung', pId: 79 },
  { id: 84, value: 'Google', pId: 79 },
  { id: 85, value: 'Computers', pId: 51 },
  { id: 86, value: 'Accessories', pId: 85 },
  { id: 87, value: 'Docks', pId: 86 },
  { id: 88, value: 'Keyboards & Mice', pId: 86 },
  { id: 89, value: 'Keyboards & Mice', pId: 86 },
  { id: 90, value: 'Other', pId: 86 },
  { id: 91, value: 'Pen / Stylus', pId: 86 },
  { id: 920, value: 'Non-Powered', pId: 91 },
  { id: 921, value: 'Powered', pId: 91 },
  { id: 93, value: 'Power Adapters & Chargers', pId: 86 },
  { id: 94, value: 'Computer Components', pId: 85 },
  { id: 95, value: 'Cooling Components', pId: 94 },
  { id: 96, value: 'CPU/Processors', pId: 94 },
  { id: 97, value: 'GPU/Graphics Cards', pId: 94 },
  { id: 98, value: 'Internal/External Batteries', pId: 94 },
  { id: 99, value: 'Internal/External Storage', pId: 94 },
  { id: 100, value: 'Miscellaneous Components', pId: 94 },
  { id: 101, value: 'Motherboards', pId: 94 },
  { id: 102, value: 'Network Adapters', pId: 94 },
  { id: 103, value: 'Optical Drives', pId: 94 },
  { id: 104, value: 'PSU/Power Supplies', pId: 94 },
  { id: 105, value: 'RAM/Memory', pId: 94 },
  { id: 106, value: 'Screens/Digitizers', pId: 94 },
  { id: 107, value: 'Digitizers', pId: 94 },
  { id: 108, value: 'Tower/Cases', pId: 94 },
  { id: 109, value: 'Computer Software', pId: 85 },
  { id: 110, value: 'Games', pId: 109 },
  { id: 111, value: 'Software', pId: 109 },
  { id: 112, value: 'FlashDrives/SD/Storage Media', pId: 85 },
  { id: 113, value: 'Security & Surveillance', pId: 51 },
  { id: 114, value: 'Smart Home', pId: 51 },
  { id: 115, value: 'Digital Assistant', pId: 114 },
  { id: 116, value: 'Amazon', pId: 115 },
  { id: 117, value: 'Apple', pId: 115 },
  { id: 118, value: 'Google', pId: 115 },
  { id: 119, value: 'Other', pId: 115 },
  { id: 120, value: 'Desktops', pId: 85 },
  { id: 121, value: 'All In One Computers', pId: 85 },
  { id: 122, value: 'Laptops', pId: 85 },
  { id: 123, value: 'Monitors', pId: 85 },
  { id: 124, value: 'Networking', pId: 85 },
  { id: 125, value: 'Digital Picture Frames', pId: 51 },
  { id: 126, value: 'Drones & Quadcopters', pId: 51 },
  { id: 127, value: 'Accessories', pId: 51 },
  { id: 128, value: 'Drones & Quadcopters Vehicles', pId: 127 },
  { id: 129, value: 'GPS & Navigation', pId: 51 },
  { id: 130, value: 'GPS Unit', pId: 129 },
  { id: 131, value: 'GPS Accessories', pId: 129 },
  { id: 132, value: 'Headphones & Portable Speakers', pId: 51 },
  { id: 133, value: 'Accessories', pId: 132 },
  { id: 134, value: 'In Ear Headphones', pId: 132 },
  { id: 135, value: 'Over Ear Headphones', pId: 132 },
  { id: 136, value: 'Portable Speakers', pId: 132 },
  { id: 137, value: 'Home Audio & Theater', pId: 51 },
  { id: 138, value: 'Accessories', pId: 137 },
  { id: 139, value: 'Clock Radio', pId: 137 },
  { id: 140, value: 'DVD & Blu-ray Players', pId: 137 },
  { id: 141, value: 'Home Theatre In a Box', pId: 137 },
  { id: 142, value: 'Media Streaming Players (IPTV)', pId: 137 },
  { id: 143, value: 'Receivers, CD Players, Turntables', pId: 137 },
  { id: 144, value: 'Receivers', pId: 137 },
  { id: 145, value: 'Shelf Stereo System', pId: 137 },
  { id: 146, value: 'Speakers', pId: 137 },
  { id: 147, value: 'Home Phones', pId: 51 },
  { id: 148, value: 'Accessories', pId: 147 },
  { id: 149, value: 'Cordless / Corded Phones', pId: 147 },
  { id: 150, value: 'Portable Audio, Video Player', pId: 51 },
  { id: 151, value: 'Accessories', pId: 150 },
  { id: 152, value: 'Apple iPods', pId: 150 },
  { id: 153, value: 'Boombox', pId: 150 },
  { id: 154, value: 'Media, CD, Tape, Mini Disc', pId: 150 },
  { id: 155, value: 'Recorders', pId: 150 },
  { id: 156, value: 'Printers, Scanners & Faxes', pId: 51 },
  { id: 157, value: '3D', pId: 156 },
  { id: 158, value: 'All-In-One', pId: 156 },
  { id: 159, value: 'Faxes', pId: 156 },
  { id: 160, value: 'Ink, Toner, Accessories & Supplies', pId: 156 },
  { id: 161, value: 'Inkjet', pId: 156 },
  { id: 162, value: 'Laser', pId: 156 },
  { id: 163, value: 'Photo, Thermal & Dot Matrix', pId: 156 },
  { id: 164, value: 'Scanners', pId: 156 },
  { id: 165, value: 'Tablets & eReaders', pId: 51 },
  { id: 166, value: 'Apple iPads', pId: 165 },
  { id: 167, value: 'Microsoft Surface', pId: 165 },
  { id: 168, value: 'Other', pId: 165 },
  { id: 169, value: 'Samsung', pId: 165 },
  { id: 170, value: 'Accessories', pId: 165 },
  { id: 171, value: 'Apple iPad', pId: 170 },
  { id: 172, value: 'Microsoft Surface', pId: 170 },
  { id: 173, value: 'Other', pId: 170 },
  { id: 174, value: 'Samsung', pId: 170 },
  { id: 175, value: 'TV', pId: 51 },
  { id: 176, value: 'LED & LCD', pId: 175 },
  { id: 177, value: 'Projector', pId: 175 },
  { id: 178, value: 'Projector Lamps', pId: 175 },
  { id: 179, value: 'Video Games', pId: 51 },
  { id: 180, value: 'Consoles', pId: 179 },
  { id: 181, value: 'Microsoft', pId: 180 },
  { id: 182, value: 'Nintendo', pId: 180 },
  { id: 183, value: 'Other', pId: 180 },
  { id: 184, value: 'Sony', pId: 180 },
  { id: 185, value: 'Handheld Systems', pId: 179 },
  { id: 186, value: 'Nintendo', pId: 185 },
  { id: 187, value: 'Other', pId: 185 },
  { id: 188, value: 'Sony', pId: 185 },
  { id: 189, value: 'Accessories', pId: 179 },
  { id: 190, value: 'Batteries & Chargers', pId: 189 },
  { id: 191, value: 'Cables & Adapters', pId: 189 },
  { id: 192, value: 'Cases & Skins', pId: 189 },
  { id: 193, value: 'Chairs', pId: 189 },
  { id: 194, value: 'Controllers', pId: 189 },
  { id: 195, value: 'Microsoft', pId: 194 },
  { id: 196, value: 'Nintendo', pId: 194 },
  { id: 197, value: 'Other', pId: 194 },
  { id: 198, value: 'Sony', pId: 194 },
  { id: 199, value: 'Gaming Figures', pId: 189 },
  { id: 200, value: 'Gaming Guides', pId: 189 },
  { id: 201, value: 'Headsets', pId: 189 },
  { id: 202, value: 'Audio Headsets', pId: 201 },
  { id: 203, value: 'Virtual Reality Headsets', pId: 201 },
  { id: 204, value: 'Memory & Hard Drives', pId: 189 },
  { id: 205, value: 'Sensor Bars, Cameras & Kinects', pId: 189 },
  { id: 206, value: 'Stylus & Screen Protectors', pId: 189 },
  { id: 207, value: 'Wearable Technology', pId: 51 },
  { id: 208, value: 'Accessories', pId: 207 },
  { id: 209, value: 'Apple Watch', pId: 208 },
  { id: 210, value: 'Fitbit', pId: 208 },
  { id: 211, value: 'Other', pId: 208 },
  { id: 212, value: 'Samsung Gear', pId: 208 },
  { id: 213, value: 'Watch', pId: 207 },
  { id: 214, value: 'Apple Watch', pId: 213 },
  { id: 215, value: 'Fitbit', pId: 213 },
  { id: 216, value: 'Other', pId: 213 },
  { id: 217, value: 'Samsung Gear', pId: 213 },
  { id: 218, value: 'Food' },
  { id: 219, value: 'Gourmet Grocery', pId: 218 },
  { id: 220, value: 'Pantry', pId: 218 },
  { id: 221, value: 'Furniture' },
  { id: 222, value: 'Baby', pId: 221 },
  { id: 223, value: 'Bathroom', pId: 221 },
  { id: 224, value: 'Bedroom', pId: 221 },
  { id: 225, value: 'Dining Room & Kitchen', pId: 221 },
  { id: 226, value: 'Kids', pId: 221 },
  { id: 227, value: 'Living Room', pId: 221 },
  { id: 228, value: 'Mattresses', pId: 221 },
  { id: 229, value: 'Office', pId: 221 },
  { id: 230, value: 'Patio', pId: 221 },
  { id: 231, value: 'TV Stands, Wall Mounts & Entertainment Centers', pId: 221 },
  { id: 232, value: 'Health & Beauty' },
  { id: 233, value: 'Bath & Body', pId: 232 },
  { id: 234, value: 'Canes, Walkers, Wheelchairs & Mobility', pId: 232 },
  { id: 235, value: 'Diet, Nutrition, Vitamins & Supplements', pId: 232 },
  { id: 236, value: 'First Aid & Home Treatment', pId: 232 },
  { id: 237, value: 'Fragrances', pId: 232 },
  { id: 238, value: 'Hair Care', pId: 232 },
  { id: 239, value: 'Home Health Care', pId: 232 },
  { id: 240, value: 'Makeup', pId: 232 },
  { id: 241, value: 'Massagers & Spa', pId: 232 },
  { id: 242, value: 'Medical Devices & Daily Living Aids', pId: 232 },
  { id: 243, value: 'Oral Care', pId: 232 },
  { id: 244, value: 'Sexual Wellness & Family Planning', pId: 232 },
  { id: 245, value: 'Shaving', pId: 232 },
  { id: 246, value: 'Vision & Optical', pId: 232 },
  { id: 247, value: 'Home' },
  { id: 248, value: 'Appliances', pId: 247 },
  { id: 249, value: 'Accessories', pId: 248 },
  { id: 250, value: 'Major Appliances', pId: 248 },
  { id: 251, value: 'Dishwashers', pId: 250 },
  { id: 252, value: 'Freezers', pId: 250 },
  { id: 253, value: 'Ice Makers', pId: 250 },
  { id: 254, value: 'Laundry', pId: 250 },
  { id: 255, value: 'Ovens / Ranges', pId: 250 },
  { id: 256, value: 'Refrigerators', pId: 250 },
  { id: 257, value: 'Water Heaters', pId: 250 },
  { id: 258, value: 'Electric', pId: 257 },
  { id: 259, value: 'Gas', pId: 257 },
  { id: 260, value: 'Small Appliances', pId: 248 },
  { id: 261, value: 'Bar Refrigerators & Water Coolers', pId: 260 },
  { id: 262, value: 'Can Openers', pId: 260 },
  { id: 263, value: 'Coffee & Beverage Machines', pId: 260 },
  { id: 264, value: 'Drip Brewers / Perculators', pId: 263 },
  { id: 265, value: 'Single Cup Brewers', pId: 263 },
  { id: 266, value: 'Deep Fryers', pId: 260 },
  { id: 267, value: 'Food Processors, Blenders, Mixers & Ice Cream Makers', pId: 260 },
  { id: 268, value: 'Griddles & Skillets', pId: 260 },
  { id: 269, value: 'Kettles & Ice Tea Makers', pId: 260 },
  { id: 270, value: 'Microwaves', pId: 260 },
  { id: 271, value: 'Slow Cookers, Roasters, Rice Cookers & Steamers', pId: 260 },
  { id: 272, value: 'Bedding', pId: 247 },
  { id: 273, value: 'Bedding Sets', pId: 272 },
  { id: 274, value: 'Blankets, Throws & Quilts', pId: 272 },
  { id: 275, value: 'Comforters & Duvets', pId: 272 },
  { id: 276, value: 'Covers, Mattress Pads & Toppers', pId: 272 },
  { id: 277, value: 'Pillows', pId: 272 },
  { id: 278, value: 'Sheets, Pillowcases & Bed Skirts', pId: 272 },
  { id: 279, value: 'Heating & Cooling', pId: 247 },
  { id: 280, value: 'Accessories', pId: 279 },
  { id: 281, value: 'Air Conditioners', pId: 279 },
  { id: 282, value: 'Fans', pId: 279 },
  { id: 283, value: 'Fireplaces', pId: 279 },
  { id: 284, value: 'Heaters', pId: 279 },
  { id: 285, value: 'Humidifiers / De-Humidifiers', pId: 279 },
  { id: 286, value: 'Cleaning Supplies', pId: 247 },
  { id: 287, value: 'Home Security & Safety', pId: 247 },
  { id: 288, value: 'Arts & Crafts', pId: 247 },
  { id: 289, value: 'Bath', pId: 247 },
  { id: 290, value: 'Kitchen & Dining', pId: 247 },
  { id: 291, value: 'Curtains & Window Coverings', pId: 247 },
  { id: 292, value: 'Decor', pId: 247 },
  { id: 293, value: 'Floor Care', pId: 247 },
  { id: 294, value: 'Lighting & Light Fixtures', pId: 247 },
  { id: 295, value: 'Luggage', pId: 247 },
  { id: 296, value: 'Rugs & Mats', pId: 247 },
  { id: 297, value: 'Safes', pId: 247 },
  { id: 298, value: 'Smoke Alarms & CO Detectors', pId: 247 },
  { id: 299, value: 'Storage & Organization', pId: 247 },
  { id: 300, value: 'Vacuums', pId: 247 },
  { id: 301, value: 'Home Improvement' },
  { id: 302, value: 'Generators', pId: 301 },
  { id: 303, value: 'Hardware', pId: 301 },
  { id: 304, value: 'Home Automation', pId: 301 },
  { id: 305, value: 'Kitchen & Bath Fixtures', pId: 301 },
  { id: 306, value: 'Light Bulbs', pId: 301 },
  { id: 307, value: 'Non-Smart', pId: 306 },
  { id: 308, value: 'Smart', pId: 306 },
  { id: 309, value: 'Pressure Washers', pId: 301 },
  { id: 310, value: 'Tools', pId: 301 },
  { id: 311, value: 'Hand Tools', pId: 310 },
  { id: 312, value: 'Power Tools', pId: 310 },
  { id: 313, value: 'Tool Accessories', pId: 310 },
  { id: 314, value: 'Industrial Supplies' },
  { id: 315, value: 'Dental, Medical, Lab & Scientific Equipment & Supplies', pId: 314 },
  { id: 316, value: 'Food Service Equipment & Supplies', pId: 314 },
  { id: 317, value: 'Retail Equipment & Supplies', pId: 314 },
  { id: 318, value: 'Safety Clothing & Equipment', pId: 314 },
  { id: 319, value: 'Jewelry' },
  { id: 320, value: 'Bracelets', pId: 319 },
  { id: 321, value: 'Cufflinks, Tie Clips, Brooch', pId: 319 },
  { id: 322, value: 'Earrings', pId: 319 },
  { id: 323, value: 'Jewelry Storage', pId: 319 },
  { id: 324, value: 'Loose Stones', pId: 319 },
  { id: 325, value: 'Necklaces', pId: 319 },
  { id: 326, value: 'Rings', pId: 319 },
  { id: 327, value: 'Sets', pId: 319 },
  { id: 328, value: 'Watches (NOT Wearable Tech)', pId: 319 },
  { id: 329, value: 'Games', pId: 179 },
  { id: 330, value: 'Microsoft', pId: 329 },
  { id: 331, value: 'Nintendo', pId: 329 },
  { id: 332, value: 'Other', pId: 329 },
  { id: 333, value: 'Sony', pId: 329 },
  { id: 334, value: 'Movies & TV Media' },
  { id: 335, value: 'Blu-ray Discs', pId: 334 },
  { id: 336, value: 'DVD Discs', pId: 334 },
  { id: 337, value: 'Other', pId: 334 },
  { id: 338, value: 'Music' },
  { id: 339, value: 'Music CDs/Vinyl/Tapes', pId: 338 },
  { id: 340, value: 'Musical Instruments', pId: 338 },
  { id: 341, value: 'Accessories', pId: 340 },
  { id: 342, value: 'Not Powered', pId: 340 },
  { id: 343, value: 'Powered', pId: 340 },
  { id: 344, value: 'Office' },
  { id: 345, value: 'Calendars', pId: 344 },
  { id: 346, value: 'Office Electronics', pId: 344 },
  { id: 347, value: 'Binding Machines, Laminators', pId: 346 },
  { id: 348, value: 'Calculators', pId: 346 },
  { id: 349, value: 'Cash Registers', pId: 346 },
  { id: 350, value: 'Presentation Equipment', pId: 346 },
  { id: 351, value: 'Shredders', pId: 346 },
  { id: 352, value: 'Typewriters', pId: 346 },
  { id: 353, value: 'Office Supplies', pId: 344 },
  { id: 354, value: 'Party & Occasions' },
  { id: 355, value: 'Costumes', pId: 354 },
  { id: 356, value: 'Decorations & Favors', pId: 354 },
  { id: 357, value: 'Disposable Tableware', pId: 354 },
  { id: 358, value: 'Giftwrap & Supplies', pId: 354 },
  { id: 359, value: 'Stationery & Invitations', pId: 354 },
  { id: 360, value: 'Patio & Garden' },
  { id: 361, value: 'Gardening & Lawn Care', pId: 360 },
  { id: 362, value: 'Accessories', pId: 361 },
  { id: 363, value: 'Gardening Hand Tools', pId: 361 },
  { id: 364, value: 'Hedge Clippers & Chainsaws', pId: 361 },
  { id: 365, value: 'Leaf Blowers & Vaccums', pId: 361 },
  { id: 366, value: 'Mowers', pId: 361 },
  { id: 367, value: 'Other', pId: 361 },
  { id: 368, value: 'Snow Removal', pId: 361 },
  { id: 369, value: 'Trimmers & Edgers', pId: 361 },
  { id: 370, value: 'Grills & Outdoor Cooking', pId: 360 },
  { id: 371, value: 'Hot Tubs & Saunas', pId: 360 },
  { id: 372, value: 'Outdoor Play', pId: 360 },
  { id: 373, value: 'Patio & Outdoor Lighting / Decor', pId: 360 },
  { id: 374, value: 'Pools & Water Fun', pId: 360 },
  { id: 375, value: 'Pet Toys & Pet Supplies' },
  { id: 376, value: 'Sports, Fitness & Outdoors' },
  { id: 377, value: 'Boats & Water Sports', pId: 376 },
  { id: 378, value: 'Camping & Hiking', pId: 376 },
  { id: 379, value: 'Cycling & Bicycles', pId: 376 },
  { id: 380, value: 'Exercise & Fitness', pId: 376 },
  { id: 381, value: 'Fishing & Wildlife', pId: 376 },
  { id: 382, value: 'Game Room', pId: 376 },
  { id: 383, value: 'Golf', pId: 376 },
  { id: 384, value: 'Hunting', pId: 376 },
  { id: 385, value: 'Optics / Binoculars', pId: 376 },
  { id: 386, value: 'Outdoor Sports', pId: 376 },
  { id: 387, value: 'Scooters, Skateboards & Skates', pId: 376 },
  { id: 388, value: 'Not Powered', pId: 387 },
  { id: 389, value: 'Powered', pId: 387 },
  { id: 390, value: 'Shooting', pId: 376 },
  { id: 391, value: 'Firearms', pId: 376 },
  { id: 392, value: 'Amunition', pId: 376 },
  { id: 393, value: 'Tennis & Racquet', pId: 376 },
  { id: 394, value: 'Trampolines', pId: 376 },
  { id: 395, value: 'Toys' },
  { id: 396, value: 'Action Figures', pId: 395 },
  { id: 397, value: 'Baby Toys', pId: 395 },
  { id: 398, value: 'Boardgames, Puzzles & Building Blocks', pId: 395 },
  { id: 399, value: 'Dolls', pId: 395 },
  { id: 400, value: 'Instruments', pId: 395 },
  { id: 401, value: 'Not Powered', pId: 400 },
  { id: 402, value: 'Powered', pId: 400 },
  { id: 403, value: 'Learning & Education', pId: 395 },
  { id: 404, value: 'Not Powered', pId: 403 },
  { id: 405, value: 'Powered', pId: 403 },
  { id: 406, value: 'Pretend & Dress-Up', pId: 395 },
  { id: 407, value: 'Ride On Toys', pId: 395 },
  { id: 408, value: 'Accessories', pId: 407 },
  { id: 409, value: 'Vehicles', pId: 407 },
  { id: 410, value: 'Stuffed Animals', pId: 395 },
  { id: 411, value: 'Vehicles, Trains & RC', pId: 395 },
  { id: 412, value: 'Water Guns & Foam Blasters', pId: 395 },
  { id: 413, value: 'Chromebooks', pId: 85 },
].map((item, idx, arr) => ({
  ...item,
  title: item.value,
  value: item.id,
  selectable: !arr.some(it => it.pId === item.id),
}))

export const outOfValidationCategoriesIds = [
  '8', // Automotive -> Tires
  '10', // Baby -> Baby Food & Formula
  '52', // Electronics -> Batteries
  '98', // Electronics -> Computers -> Computer Components -> Internal/External Batteries
  '107', // Electronics -> Computers -> Computer Components -> Digitizers
  '154', // Electronics -> Portable Audio, Video Player -> Media, CD, Tape, Mini Disc
  '218', // Food
  '219', // Food -> Gourmet Grocery
  '220', // Food -> Pantry
  '228', // Furniture -> Mattresses
  '315', // Industrial Supplies -> Dental, Medical, Lab & Scientific Equipment & Supplies
  '391', // Sports, Fitness & Outdoors -> Firearms
  '392', // Sports, Fitness & Outdoors -> Amunition
]

export const outOfVASValidationCategoriesIds = outOfValidationCategoriesIds.concat([
  '1', // Adult
  '2', // Automotive
  '9', // Baby
  '13', // Baby -> Car Seats
  '14', // Baby -> Diapers & Wipes
  '15', // Baby -> Health & Safety
  '16', // Baby -> Nursing & Feeding Supplies
  '17', // Baby -> Strollers
  '18', // Baby -> Walkers, Swings & Bouncers
  '20', // Clothing, Shoes & Accessories
  '25', // Clothing, Shoes & Accessories -> Clothing -> Boys -> Underwear
  '27', // Clothing, Shoes & Accessories -> Clothing -> Girls -> Underwear
  '35', // Clothing, Shoes & Accessories -> Clothing -> Mens -> T-Shirts, Polos, Sweaters
  '36', // Clothing, Shoes & Accessories -> Clothing -> Mens -> Underwear & Socks
  '51', // Electronics
  '53', // Electronics -> Cameras
  '54', // Electronics -> Cameras -> Accessories
  '60', // Electronics -> Cameras -> Point & Shoot
  '61', // Electronics -> Car Audio, Video & Electronics
  '62', // Electronics -> Car Audio, Video & Electronics -> Accessories
  '63', // Electronics -> Car Audio, Video & Electronics -> Alarms
  '71', // Electronics -> Cellular Phones
  '72', // Electronics -> Cellular Phones -> Accessories
  '73', // Electronics -> Cellular Phones -> Accessories -> Cases
  '74', // Electronics -> Cellular Phones -> Accessories -> Other
  '85', // Electronics -> Computers
  '86', // Electronics -> Computers -> Accessories
  '90', // Electronics -> Computers -> Accessories -> Other
  '920', // Electronics -> Computers -> Accessories -> Pen / Stylus -> Non-Powered
  '94', // Electronics -> Computers -> Computer Components
  '95', // Electronics -> Computers -> Computer Components -> Cooling Components
  '96', // Electronics -> Computers -> Computer Components -> CPU/Processors
  '100', // Electronics -> Computers -> Computer Components -> Miscellaneous Components
  '110', // Electronics -> Computers -> Computer Software -> Games
  '111', // Electronics -> Computers -> Computer Software -> Software
  '126', // Electronics -> Drones & Quadcopters
  '127', // Electronics -> Drones & Quadcopters -> Accessories
  '131', // Electronics -> GPS & Navigation
  '129', // Electronics -> GPS & Navigation -> GPS Accessories
  '129', // Electronics -> GPS & Navigation -> GPS Accessories
  '132', // Electronics -> Headphones & Portable Speakers
  '133', // Electronics -> Headphones & Portable Speakers -> Accessories
  '137', // Electronics -> Home Audio & Theater
  '138', // Electronics -> Home Audio & Theater -> Accessories
  '148', // Electronics -> Home Phones -> Accessories
  '150', // Electronics -> Portable Audio, Video Player
  '151', // Electronics -> Portable Audio, Video Player -> Accessories
  '155', // Electronics -> Portable Audio, Video Player -> Recorders
  '156', // Electronics -> Printers, Scanners & Faxes
  '157', // Electronics -> Printers, Scanners & Faxes -> 3D
  '158', // Electronics -> Printers, Scanners & Faxes -> All-In-One
  '159', // Electronics -> Printers, Scanners & Faxes -> Faxes
  '160', // Electronics -> Printers, Scanners & Faxes -> Ink, Toner, Accessories & Supplies
  '161', // Electronics -> Printers, Scanners & Faxes -> Inkjet
  '165', // Electronics -> Tablets & eReaders
  '170', // Electronics -> Tablets & eReaders -> Accessories
  '172', // Electronics -> Tablets & eReaders -> Accessories -> Microsoft Surface
  '173', // Electronics -> Tablets & eReaders -> Accessories -> Other
  '174', // Electronics -> Tablets & eReaders -> Accessories -> Samsung
  '175', // Electronics -> TV
  '178', // Electronics -> TV -> Projector Lamps
  '179', // Electronics -> Video Games
  '180', // Electronics -> Video Games -> Consoles
  '185', // Electronics -> Video Games -> Handheld Systems
  '189', // Electronics -> Video Games -> Accessories
  '190', // Electronics -> Video Games -> Accessories -> Batteries & Chargers
  '191', // Electronics -> Video Games -> Accessories -> Cables & Adapters
  '192', // Electronics -> Video Games -> Accessories -> Cases & Skins
  '193', // Electronics -> Video Games -> Accessories -> Chairs
  '194', // Electronics -> Video Games -> Accessories -> Controllers
  '197', // Electronics -> Video Games -> Accessories -> Controllers -> Other
  '199', // Electronics -> Video Games -> Accessories -> Gaming Figures
  '200', // Electronics -> Video Games -> Accessories -> Gaming Guides
  '201', // Electronics -> Video Games -> Accessories -> Headsets
  '202', // Electronics -> Video Games -> Accessories -> Headsets -> Audio Headsets
  '203', // Electronics -> Video Games -> Accessories -> Headsets -> Virtual Reality Headsets
  '206', // Electronics -> Video Games -> Accessories -> Stylus & Screen Protectors
  '207', // Electronics -> Wearable Technology
  '208', // Electronics -> Wearable Technology -> Accessories
  '209', // Electronics -> Wearable Technology -> Accessories -> Apple Watch
  '210', // Electronics -> Wearable Technology -> Accessories -> Fitbit
  '211', // Electronics -> Wearable Technology -> Accessories -> Other
  '212', // Electronics -> Wearable Technology -> Accessories -> Samsung Gear
  '216', // Electronics -> Wearable Technology -> Watch -> Other
  '221', // Furniture
  '222', // Furniture -> Baby
  '223', // Furniture -> Bathroom
  '224', // Furniture -> Bedroom
  '225', // Furniture -> Dining Room & Kitchen
  '226', // Furniture -> Kids
  '227', // Furniture -> Living Room
  '228', // Furniture -> Mattresses
  '229', // Furniture -> Office
  '230', // Furniture -> Patio
  '231', // Furniture -> TV Stands, Wall Mounts & Entertainment Centers
  '232', // Health & Beauty
  '233', // Health & Beauty -> Bath & Body
  '234', // Health & Beauty -> Canes, Walkers, Wheelchairs & Mobility
  '235', // Health & Beauty -> Diet, Nutrition, Vitamins & Supplements
  '236', // Health & Beauty -> First Aid & Home Treatment
  '237', // Health & Beauty -> Fragrances
  '238', // Health & Beauty -> Hair Care
  '239', // Health & Beauty -> Home Health Care
  '240', // Health & Beauty -> Makeup
  '241', // Health & Beauty -> Massagers & Spa
  '242', // Health & Beauty -> Medical Devices & Daily Living Aids
  '243', // Health & Beauty -> Oral Care
  '244', // Health & Beauty -> Sexual Wellness & Family Planning
  '245', // Health & Beauty -> Shaving
  '246', // Health & Beauty -> Vision & Optical
  '247', // Home
  '248', // Home -> Appliances
  '249', // Home -> Appliances -> Accessories
  '250', // Home -> Appliances -> Major Appliances
  '251', // Home -> Appliances -> Major Appliances -> Dishwashers
  '252', // Home -> Appliances -> Major Appliances -> Freezers
  '254', // Home -> Appliances -> Major Appliances -> Laundry
  '256', // Home -> Appliances -> Major Appliances -> Refrigerators
  '257', // Home -> Appliances -> Major Appliances -> Water Heaters
  '258', // Home -> Appliances -> Major Appliances -> Water Heaters -> Electric
  '259', // Home -> Appliances -> Major Appliances -> Water Heaters -> Gas
  '260', // Home -> Appliances -> Small Appliances
  '266', // Home -> Appliances -> Small Appliances -> Deep Fryers
  '272', // Home -> Bedding
  '279', // Home -> Heating & Cooling
  '280', // Home -> Heating & Cooling -> Accessories
  '283', // Home -> Heating & Cooling -> Fireplaces
  '286', // Home -> Cleaning Supplies
  '287', // Home -> Home Security & Safety
  '288', // Home -> Arts & Crafts
  '289', // Home -> Bath
  '290', // Home -> Kitchen & Dining
  '291', // Home -> Curtains & Window Coverings
  '292', // Home -> Decor
  '293', // Home -> Floor Care
  '294', // Home -> Lighting & Light Fixtures
  '296', // Home -> Rugs & Mats
  '298', // Home -> Smoke Alarms & CO Detectors
  '299', // Home -> Storage & Organization
  '301', // Home Improvement
  '303', // Home Improvement -> Hardware
  '306', // Home Improvement -> Light Bulbs
  '307', // Home Improvement -> Light Bulbs -> Non-Smart
  '309', // Home Improvement -> Pressure Washers
  '310', // Home Improvement -> Tools
  '313', // Home Improvement -> Tools -> Tool Accessories
  '314', // Industrial Supplies
  '316', // Industrial Supplies -> Food Service Equipment & Supplies
  '317', // Industrial Supplies -> Retail Equipment & Supplies
  '318', // Industrial Supplies -> Safety Clothing & Equipment
  '319', // Jewelry
  '329', // Electronics -> Video Games -> Games
  '330', // Electronics -> Video Games -> Games -> Microsoft
  '331', // Electronics -> Video Games -> Games -> Nintendo
  '332', // Electronics -> Video Games -> Games -> Other
  '333', // Electronics -> Video Games -> Games -> Sony
  '334', // Movies & TV Media
  '335', // Movies & TV Media -> Blu-ray Discs
  '336', // Movies & TV Media -> DVD Discs
  '337', // Movies & TV Media -> Other
  '338', // Music
  '339', // Music -> Music CDs/Vinyl/Tapes
  '340', // Music -> Musical Instruments
  '341', // Music -> Musical Instruments -> Accessories
  '344', // Office
  '345', // Office -> Calendars
  '346', // Office -> Office Electronics
  '347', // Office -> Office Electronics -> Binding Machines, Laminators
  '348', // Office -> Office Electronics -> Calculators
  '349', // Office -> Office Electronics -> Cash Registers
  '350', // Office -> Office Electronics -> Presentation Equipment
  '351', // Office -> Office Electronics -> Shredders
  '352', // Office -> Office Electronics -> Typewriters
  '353', // Office -> Office Supplies
  '354', // Party & Occasions
  '355', // Party & Occasions -> Costumes
  '356', // Party & Occasions -> Decorations & Favors
  '357', // Party & Occasions -> Disposable Tableware
  '358', // Party & Occasions -> Giftwrap & Supplies
  '359', // Party & Occasions -> Stationery & Invitations
  '360', // Patio & Garden
  '361', // Patio & Garden -> Gardening & Lawn Care
  '362', // Patio & Garden -> Gardening & Lawn Care -> Accessories
  '370', // Patio & Garden -> Grills & Outdoor Cooking
  '371', // Patio & Garden -> Hot Tubs & Saunas
  '373', // Patio & Garden -> Patio & Outdoor Lighting / Decor
  '374', // Patio & Garden -> Pools & Water Fun
  '375', // Pet Toys & Pet Supplies
  '376', // Sports, Fitness & Outdoors
  '377', // Sports, Fitness & Outdoors -> Boats & Water Sports
  '390', // Sports, Fitness & Outdoors -> Shooting
  '395', // Toys
])

// const outOfRecievingAndPutawayCategoriesIds = outOfValidationCategoriesIds.concat([
//     '2', // Automotive
//     '9', // Baby
//     '20', // Clothing, Shoes & Accessories
//     '51', // Electronics
//     '53', // Electronics -> Cameras
//     '61', // Electronics -> Car Audio, Video & Electronics
//     '71', // Electronics -> Cellular Phones
//     '85', // Electronics -> Computers
//     '86', // Electronics -> Computers -> Accessories
//     '95', // Electronics -> Computers -> Computer Components
//     '126', // Electronics -> Drones & Quadcopters
//     '129', // Electronics -> GPS & Navigation
//     '132', // Electronics -> Headphones & Portable Speakers
//     '137', // Electronics -> Home Audio & Theater
//     '150', // Electronics -> Portable Audio, Video Player
//     '156', // Electronics -> Printers, Scanners & Faxes
//     '165', // Electronics -> Tablets & eReaders
//     '170', // Electronics -> Tablets & eReaders -> Accessories
//     '175', // Electronics -> TV
//     '179', // Electronics -> Video Games
//     '189', // Electronics -> Video Games -> Accessories
//     '207', // Electronics -> Wearable Technolog
//     '221', // Furniture
//     '232', // Health & Beauty
//     '247', // Home
//     '248', // Home -> Appliances
//     '272', // Home -> Bedding
//     '279', // Home -> Heating & Cooling
//     '301', // Home Improvement
//     '319', // Jewelry
//     '329', // Electronics -> Video Games -> Games
//     '338', // Music
//     '340', // Music -> Musical Instruments
//     '344', // Office
//     '354', // Party & Occasions
//     '360', // Patio & Garden
//     '376', // Sports, Fitness & Outdoors
//     '395', // Toys
// ])
