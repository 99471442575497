type CalculationParams = {
  weight: number
  weightUnits: string
  categoryId: number
}

type CategoryType =
  | 'cat1'
  | 'cat2'
  | 'cat3'
  | 'cat4'
  | 'cat5'
  | 'cat6'
  | 'cat7'
  | 'cat8'
  | 'cat9'
  | 'cat10'
  | 'cat11'
  | 'cat12'
  | 'cat13'

type CategoryWeightMap = {
  [key: number]: {
    [key in CategoryType]: number
  }
}

const ouncesWeightUnits = Array.from(Array(9).keys()).map(i => i + 8)
const ouncesWeightValuesCat1 = [1.99, 2.24, 2.49, 2.74, 2.99, 3.24, 3.48, 3.73, 3.98]
const ouncesWeightValuesCat2 = [2.14, 2.41, 2.68, 2.95, 3.22, 3.48, 3.75, 4.02, 4.29]
const ouncesWeightValuesCat3 = [2.45, 2.75, 3.06, 3.37, 3.67, 3.98, 4.29, 4.59, 4.9]
const ouncesWeightValuesCat4 = [3.04, 3.42, 3.8, 4.18, 4.56, 4.94, 5.32, 5.7, 6.08]
const ouncesWeightValuesCat5 = [3.06, 3.44, 3.82, 4.21, 4.59, 4.97, 5.35, 5.74, 6.12]
const ouncesWeightValuesCat6 = [3.26, 3.67, 4.08, 4.49, 4.89, 5.3, 5.71, 6.12, 6.53]
const ouncesWeightValuesCat7 = [2.77, 3.12, 3.47, 3.81, 4.16, 4.51, 4.85, 5.2, 5.55]
const ouncesWeightValuesCat8 = [4.15, 4.67, 5.18, 5.7, 6.22, 6.22, 6.22, 6.22, 6.22]
const ouncesWeightValuesCat9 = [3.41, 3.84, 4.27, 4.69, 5.12, 5.55, 5.97, 6.4, 6.83]
const ouncesWeightValuesCat10 = [2.04, 2.29, 2.55, 2.8, 3.06, 3.31, 3.57, 3.82, 4.08]
const ouncesWeightValuesCat11 = [2.75, 3.1, 3.44, 3.79, 4.13, 4.47, 4.82, 5.16, 5.51]
const ouncesWeightValuesCat12 = [2.3, 2.58, 2.87, 3.16, 3.44, 3.73, 4.02, 4.31, 4.59]
const ouncesWeightValuesCat13 = [1.84, 2.07, 2.3, 2.53, 2.76, 2.99, 3.22, 3.45, 3.68]

const ouncesWeightMap: CategoryWeightMap = ouncesWeightUnits.reduce(
  (acc, curr, index) => ({
    ...acc,
    [curr]: {
      cat1: ouncesWeightValuesCat1[index],
      cat2: ouncesWeightValuesCat2[index],
      cat3: ouncesWeightValuesCat3[index],
      cat4: ouncesWeightValuesCat4[index],
      cat5: ouncesWeightValuesCat5[index],
      cat6: ouncesWeightValuesCat6[index],
      cat7: ouncesWeightValuesCat7[index],
      cat8: ouncesWeightValuesCat8[index],
      cat9: ouncesWeightValuesCat9[index],
      cat10: ouncesWeightValuesCat10[index],
      cat11: ouncesWeightValuesCat11[index],
      cat12: ouncesWeightValuesCat12[index],
      cat13: ouncesWeightValuesCat13[index],
    },
  }),
  {},
)

const poundsWeightUnits = Array.from(Array(55).keys()).map(i => i + 1)
const poundsWeightValuesCat1 = [
  3.98, 3.98, 4.02, 4.05, 4.09, 4.15, 4.21, 4.27, 4.34, 4.4, 4.45, 4.51, 4.56, 4.61, 4.67, 4.72,
  4.78, 4.83, 4.88, 4.94, 4.95, 4.96, 4.97, 4.98, 4.99, 5.0, 5.01, 5.02, 5.03, 5.04, 5.05, 5.06,
  5.07, 5.09, 5.1, 5.11, 5.12, 5.13, 5.14, 5.15, 5.16, 5.17, 5.18, 5.19, 5.2, 5.21, 5.22, 5.23,
  5.24, 5.25, 5.73, 6.2, 6.67, 7.15, 7.62,
]
const poundsWeightValuesCat2 = [
  4.29, 4.29, 4.36, 4.43, 4.5, 4.62, 4.75, 4.87, 4.99, 5.12, 5.23, 5.33, 5.44, 5.55, 5.66, 5.77,
  5.88, 5.98, 6.09, 6.2, 6.22, 6.24, 6.26, 6.28, 6.31, 6.33, 6.35, 6.37, 6.39, 6.41, 6.43, 6.45,
  6.47, 6.49, 6.52, 6.54, 6.56, 6.58, 6.6, 6.62, 6.64, 6.66, 6.68, 6.7, 6.73, 6.75, 6.77, 6.79,
  6.81, 6.83, 7.78, 8.72, 9.67, 10.62, 11.56,
]
const poundsWeightValuesCat3 = [
  4.9, 4.9, 5.04, 5.18, 5.32, 5.57, 5.82, 6.07, 6.31, 6.56, 6.78, 6.99, 7.21, 7.43, 7.64, 7.86,
  8.07, 8.29, 8.51, 8.72, 8.77, 8.81, 8.85, 8.89, 8.93, 8.98, 9.02, 9.06, 9.1, 9.14, 9.19, 9.23,
  9.27, 9.31, 9.35, 9.4, 9.44, 9.48, 9.52, 9.56, 9.61, 9.65, 9.69, 9.73, 9.78, 9.82, 9.86, 9.9,
  9.94, 9.99, 11.88, 13.77, 15.66, 17.56, 19.45,
]
const poundsWeightValuesCat4 = [
  6.08, 6.08, 6.36, 6.64, 6.92, 7.4, 7.89, 8.38, 8.86, 9.35, 9.77, 10.2, 10.62, 11.05, 11.47, 11.9,
  12.32, 12.75, 13.18, 13.6, 13.68, 13.77, 13.85, 13.93, 14.01, 14.1, 14.18, 14.26, 14.34, 14.43,
  14.51, 14.59, 14.68, 14.76, 14.84, 14.92, 15.01, 15.09, 15.17, 15.25, 15.34, 15.42, 15.5, 15.59,
  15.67, 15.67, 15.67, 15.67, 15.67, 15.67, 15.67, 15.67, 15.67, 15.67, 15.67,
]

const poundsWeightValuesCat5 = [6.12, 6.12, 6.4, 6.69, 6.97].concat(
  Array.from(Array(50)).map(() => 6.97),
)
const poundsWeightValuesCat6 = [6.53, 6.53, 6.86, 7.19].concat(
  Array.from(Array(51)).map(() => 7.19),
)

const poundsWeightValuesCat7 = [
  5.55, 5.55, 5.77, 5.98, 6.2, 6.58, 6.96, 7.34, 7.72, 8.1, 8.43, 8.76, 9.09, 9.42, 9.75, 10.08,
  10.41, 10.75, 11.08, 11.41,
].concat(Array.from(Array(35)).map(() => 11.41))
const poundsWeightValuesCat8 = Array.from(Array(55)).map(() => 6.22)
const poundsWeightValuesCat9 = [
  6.83, 6.83, 7.19, 7.56, 7.93, 8.57, 9.2, 9.84, 10.48, 11.12, 11.67, 12.23, 12.79, 13.35, 13.91,
  14.46, 15.02, 15.58, 16.14, 16.7, 16.81, 16.91, 17.02, 17.13, 17.24, 17.35, 17.46, 17.56, 17.67,
  17.78, 17.89, 18.0, 18.11, 18.22, 18.32, 18.43, 18.54, 18.65, 18.76, 18.87, 18.98, 19.08, 19.19,
  19.3, 19.41, 19.52, 19.63, 19.73, 19.84, 19.95, 20.77, 21.58, 22.39, 23.21, 24.02,
]
const poundsWeightValuesCat10 = [
  4.08, 4.08, 4.13, 4.17, 4.22, 4.3, 4.38, 4.47, 4.55, 4.63, 4.7, 4.77, 4.84, 4.91, 4.99, 5.06,
  5.13, 5.2, 5.27, 5.34,
].concat(Array.from(Array(35)).map(() => 5.34))
const poundsWeightValuesCat11 = [
  5.51, 5.51, 5.72, 5.93, 6.15, 6.52, 6.89, 7.26, 7.63, 8.0, 8.33, 8.65, 8.98, 9.3, 9.62, 9.95,
  10.27, 10.6, 10.92, 11.25, 11.31, 11.37, 11.44, 11.5, 11.56, 11.63, 11.69, 11.75, 11.81, 11.88,
  11.94, 12.0, 12.07, 12.13, 12.19, 12.26, 12.32, 12.38, 12.45, 12.51, 12.57, 12.63, 12.7, 12.76,
  12.82, 12.89, 12.95, 13.01, 13.08, 13.14, 13.61, 14.09, 14.56, 15.03, 15.51,
]

const poundsWeightValuesCat12 = [
  4.59, 4.59, 4.7, 4.81, 4.91, 5.1, 5.28, 5.47, 5.65, 5.84, 6.0, 6.16, 6.33, 6.49, 6.65, 6.81, 6.98,
  7.14, 7.3, 7.46, 7.49, 7.52, 7.56, 7.59, 7.62, 7.65, 7.68, 7.71, 7.75, 7.78, 7.81, 7.84, 7.87,
  7.9, 7.93, 7.97, 8.0, 8.03, 8.06, 8.09, 8.12, 8.16, 8.19, 8.22, 8.25, 8.28, 8.31, 8.35, 8.38,
  8.41, 9.83, 11.25, 12.67, 14.09, 15.51,
]
const poundsWeightValuesCat13 = Array.from(Array(55)).map(() => 3.68)

const poundsWeightMap: CategoryWeightMap = poundsWeightUnits.reduce(
  (acc, curr, index) => ({
    ...acc,
    [curr]: {
      cat1: poundsWeightValuesCat1[index],
      cat2: poundsWeightValuesCat2[index],
      cat3: poundsWeightValuesCat3[index],
      cat4: poundsWeightValuesCat4[index],
      cat5: poundsWeightValuesCat5[index],
      cat6: poundsWeightValuesCat6[index],
      cat7: poundsWeightValuesCat7[index],
      cat8: poundsWeightValuesCat8[index],
      cat9: poundsWeightValuesCat9[index],
      cat10: poundsWeightValuesCat10[index],
      cat11: poundsWeightValuesCat11[index],
      cat12: poundsWeightValuesCat12[index],
      cat13: poundsWeightValuesCat13[index],
    },
  }),
  {},
)

export const calculateSupplyChainServicesValue = ({
  weight,
  weightUnits,
  categoryId,
}: CalculationParams): number => {
  if (!categoryId) {
    return 0
  }

  const automotiveBabyBooksClothing = categoryId >= 2 && categoryId <= 50 // Automotive, Baby, Books, Clothing category
  const camerasAndCarAudio = categoryId >= 51 && categoryId <= 70 // Electronics, Cameras, Car Audio
  const computerAccessories =
    (categoryId >= 85 && categoryId <= 119) || [142, 920, 921].includes(categoryId) // Computer Accessories
  const cellularPhones = categoryId >= 71 && categoryId <= 84 // Cellular Phones
  const computers = categoryId >= 120 && categoryId <= 124 // Computers
  const specificElectronics = [125, 142, 413].includes(categoryId) // specific Electronics
  const dronesAndGps = categoryId >= 126 && categoryId <= 131 // Drones, GPS
  const headphones = categoryId >= 132 && categoryId <= 136 // Headphones
  const homeAudioVideoAndPrinters =
    categoryId !== 142 &&
    ((categoryId >= 137 && categoryId <= 155) || (categoryId >= 161 && categoryId <= 164)) // Home Audio, Video, Printing excluding IPTV
  const printing = categoryId >= 157 && categoryId <= 160 // Printing
  const tablets = categoryId >= 165 && categoryId <= 169 // Tablets
  const tabletAccessories = categoryId >= 170 && categoryId <= 174 // Tablet Accessories
  const tv = categoryId >= 175 && categoryId <= 178 // TV
  const videoGames = categoryId >= 179 && categoryId <= 188 // Video games
  const videoGamesAccessories = categoryId >= 189 && categoryId <= 206 // Video games accessories
  const wearableTech = categoryId >= 207 && categoryId <= 217 // Wearable tech
  const furnitureHealthAndBeauty = categoryId >= 221 && categoryId <= 246 // Furniture
  const homeAppliances = categoryId >= 247 && categoryId <= 271 // Home appliances
  const bedding = categoryId >= 272 && categoryId <= 278 // Bedding
  const homeHeatingCooling = categoryId >= 279 && categoryId <= 285 // Home heating & cooling
  const homeOther = categoryId >= 286 && categoryId <= 300 // Home other
  const homeImprovement = categoryId >= 301 && categoryId <= 313 // Home improvement
  const jewelry = categoryId >= 319 && categoryId <= 328 // Jewelry
  const dvdDiscs = categoryId >= 334 && categoryId <= 337 // DVD discs
  const musicalInstruments = categoryId >= 340 && categoryId <= 343 // Musical instruments
  const officeAndParty = categoryId >= 344 && categoryId <= 359 // Office & party
  const patioGarden = categoryId >= 360 && categoryId <= 374 // Patio & garden
  const sporting = categoryId >= 376 && categoryId <= 394 // Sporting
  const toys = categoryId >= 395 && categoryId <= 412 // Toys

  let category: CategoryType = 'cat1'

  if (automotiveBabyBooksClothing) {
    category = 'cat1'
  } else if (
    camerasAndCarAudio ||
    computerAccessories ||
    specificElectronics ||
    tabletAccessories ||
    videoGamesAccessories ||
    jewelry ||
    musicalInstruments ||
    sporting
  ) {
    category = 'cat2'
  } else if (
    cellularPhones ||
    dronesAndGps ||
    homeAudioVideoAndPrinters ||
    homeOther ||
    homeImprovement
  ) {
    category = 'cat3'
  } else if (computers) {
    category = 'cat4'
  } else if (headphones) {
    category = 'cat5'
  } else if (tablets) {
    category = 'cat6'
  } else if (videoGames) {
    category = 'cat7'
  } else if (wearableTech) {
    category = 'cat8'
  } else if (homeAppliances || tv) {
    category = 'cat9'
  } else if (bedding || toys) {
    category = 'cat10'
  } else if (homeHeatingCooling) {
    category = 'cat11'
  } else if (patioGarden) {
    category = 'cat12'
  } else if (printing || dvdDiscs || officeAndParty || furnitureHealthAndBeauty) {
    category = 'cat13'
  }

  if (weightUnits === 'ounces') {
    let weightValue: number = 0

    if (weight === 0) {
      return 0
    }

    if (weight <= 8) {
      weightValue = 8
    } else if (weight >= 16) {
      weightValue = 16
    } else {
      weightValue = Math.ceil(weight)
    }

    return ouncesWeightMap[weightValue][category]
  } else if (weightUnits === 'pounds') {
    let weightValue: number = 0

    if (weight === 0) {
      return 0
    }

    if (weight <= 1) {
      weightValue = 1
    } else if (weight >= 55) {
      weightValue = 55
    } else {
      weightValue = Math.ceil(weight)
    }

    return poundsWeightMap[weightValue][category]
  }

  return 0
}
