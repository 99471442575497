import { SubscriptionPlan } from '@r1-oas/public-subscription-plans'
import { Calendly } from 'calendly'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { publicSubscriptionsPlansApi } from '../api/public-subscription-plans'
import TooltipIcon from '../assets/icons/tooltip-icon.svg'
import { Button, ButtonLink } from '../components/Button'
import { CallToAction } from '../components/CallToAction'
import { Container } from '../components/Container'
import { FeesCalculator } from '../components/FeesCalculator'
import { PlatformFeatures } from '../components/PlatformFeatures'
import { SEO } from '../components/SEO'
import { SubscriptionPlans } from '../components/SubscriptionPlans'
import { Table } from '../components/Table'
import { Typography } from '../components/Typography'
import { useSupplyChainServicesTable } from '../hooks/useSupplyChainServicesTable'

const baseTagStyles = 'absolute shadow-base rounded-md text-grey-700 p-3 bg-grey-200'

const Pricing = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[]>([])
  const { supplyChainTableColumns, supplyChainTableData } = useSupplyChainServicesTable()

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await publicSubscriptionsPlansApi.getAll()

        if (response.success) {
          setSubscriptionPlans(response.body)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchPlans()
  }, [])

  const handleScheduleDemo = () => {
    Calendly.initPopupWidget({ utm_medium: 'pricing', utm_source: 'website' })
  }

  return (
    <>
      {/* Hero section */}
      <section className="flex gap-10 bg-grain-texture-pattern">
        <Container>
          <div className="flex gap-10">
            <div className="w-1/2 py-14 max-md:w-full">
              <Typography.H2 className="mb-3 max-w-[69rem] text-[3.9375rem] leading-[1.1]">
                Empowering you to better manage and control your marketplace returns
              </Typography.H2>
              <Typography.H1 className="mb-10">
                Competitive pricing based on your returns needs.
              </Typography.H1>
              <div className="flex flex-wrap gap-5 text-[1.125rem]">
                <ButtonLink size="md" to="#saas">
                  SaaS
                </ButtonLink>
                <ButtonLink size="md" to="#supply-chain">
                  Supply Chain
                </ButtonLink>
                <ButtonLink size="md" to="#recommerce">
                  ReCommerce
                </ButtonLink>
              </div>
            </div>
            <div className="w-1/2 max-md:hidden">
              <div className="absolute -right-[10.625rem] -top-[36.25rem] z-20 h-[54rem] w-[54rem] rounded-full bg-white opacity-90" />
              <div className="absolute -right-[6.25rem] z-30 h-[40rem] w-[40rem] rounded-full bg-pricing-hero-image bg-[35%_57%] font-roboto">
                <div className={cn(baseTagStyles, 'bottom-[6.25rem] left-[1.875rem] scale-[1.11]')}>
                  Missing Parts or Accessories
                </div>
                <div
                  className={cn(
                    baseTagStyles,
                    '-left-20 bottom-[13.5rem] scale-[1.32] max-xl:-left-12 max-lg:-left-6',
                  )}
                >
                  Defective or Damaged Items
                </div>
                <div
                  className={cn(
                    baseTagStyles,
                    '-left-[7.25rem] bottom-80 scale-[1.58] max-xl:-left-[3.25rem] max-lg:-left-[1.25rem]',
                  )}
                >
                  Item Not as Described
                </div>
                <div
                  className={cn(
                    baseTagStyles,
                    '-left-16 bottom-[28.75rem] scale-[1.2] max-xl:-left-8 max-lg:-left-4',
                  )}
                >
                  Wrong Item Recieved
                </div>
                <div className="absolute -left-14 bottom-20 -z-10 h-[9.75rem] w-[9.75rem] bg-cross-shape bg-cover bg-no-repeat" />
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* Returns SaaS pricing table section */}
      <section id="saas" className="pb-0">
        <Container>
          <Typography.H2 className="mb-10">Returns SaaS Pricing</Typography.H2>
          <p className="body-text mb-10 text-nav-item-text">
            Simple pricing based on returns volume.
          </p>

          <div className="flex flex-col">
            <SubscriptionPlans subscriptionPlans={subscriptionPlans} />
            <PlatformFeatures />
          </div>
        </Container>
      </section>

      <section id="fees-calculator" className="bg-white py-10">
        <Container>
          <FeesCalculator />
          <div className="mt-20 flex items-center justify-between rounded-xl bg-[#EAF2FB] p-10">
            <Typography.H4>Take a look at our full list of rates</Typography.H4>
            <ButtonLink to="#" variant="secondary">
              Download rates
            </ButtonLink>
          </div>
        </Container>
      </section>

      {/* Reverse Supply Chain Services table section */}
      <section id="supply-chain">
        <Container>
          <Typography.H2 className="mb-10">Reverse Supply Chain Services</Typography.H2>
          <Table
            columns={supplyChainTableColumns}
            rows={supplyChainTableData}
            showHeaderShadow={false}
          />
        </Container>
      </section>

      {/* Recommerce section */}
      <section id="recommerce" className="py-10">
        <Container>
          <Typography.H2 className="mb-5">ReCommerce</Typography.H2>
          <div className="flex items-center gap-1">
            <p className="body-text font-uniform-rnd text-nav-item-text">
              ReturnPro charges 10% of the final sale price for each sale.
            </p>
            <TooltipIcon className="w-5" />
          </div>
        </Container>
      </section>

      {/* CTA section */}
      <CallToAction>
        <Button variant="reversed" className="max-sm:w-full" onClick={handleScheduleDemo}>
          Schedule a demo
        </Button>
      </CallToAction>
    </>
  )
}

export default Pricing

export const Head = () => <SEO title="Pricing" />
