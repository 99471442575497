import React from 'react'

export const PriceColumn = ({ prices }: { prices: string[] }) => {
  return (
    <ul className="flex flex-col gap-4 font-uniform-rnd">
      {prices.map((price, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <p className="body-text">{price}</p>
        </li>
      ))}
    </ul>
  )
}
