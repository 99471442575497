import React, { useState } from 'react'
import {
  TreeSelect,
  Form,
  InputNumber,
  Select,
  Flex,
  Checkbox,
  Radio,
  RadioChangeEvent,
} from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Collapse } from '../Collapse'
import { Typography } from '../Typography'
import { weightUnits, categories } from './constants'
import { DimensionValueTypes, PeriodTypes, ProductForm } from './types'
import { validateProduct } from './validators'
import {
  calculateStorage,
  calculateValueAddedServicesValue,
  calculateSupplyChainServicesValue,
} from './calculations'
import './styles.css'

const DimensionSuffix = ({ children }: { children: React.ReactNode }) => (
  <div className="flex items-center justify-center bg-transparent px-3 py-0.5 text-xs text-[#16293F]">
    {children}
  </div>
)
const ErrorText = ({ children }: { children: React.ReactNode }) => (
  <div className="py-1.5 text-xs text-[#F44336]">{children}</div>
)

export const FeesCalculator = () => {
  const [state, setState] = useState<ProductForm>({
    dimDropdownValue: 'inches',
    weightDropdownValue: 'pounds',
    category: null,
    dimValue: {
      valueL: null,
      valueW: null,
      valueH: null,
      weight: null,
    },
    vas: false,
  })
  const [period, setPeriod] = React.useState<PeriodTypes>(PeriodTypes.ninety_days)

  const onChangeInput = (value: string | null) => (elem: DimensionValueTypes) =>
    setState(prev => ({
      ...prev,
      dimValue: { ...prev.dimValue, [elem]: value },
    }))

  const onChangeDropdown = (name: string) => (value: string | null) =>
    setState(prev => ({ ...prev, [name]: value }))

  const onChangeVASCheckbox = (event: CheckboxChangeEvent) =>
    setState(prev => ({
      ...prev,
      vas: event.target.checked,
    }))
  const onChangePeriod = (event: RadioChangeEvent) => {
    setPeriod(event.target.value)
  }

  const checkboxesDisabled =
    !state.dimDropdownValue || !state.weightDropdownValue || !state.category
  const isInitalDimState =
    state.dimValue[DimensionValueTypes.H] === null &&
    state.dimValue[DimensionValueTypes.L] === null &&
    state.dimValue[DimensionValueTypes.W] === null
  const {
    dimensionsError,
    vasDimensionsError,
    vasWeightError,
    nonCalculatable,
    nonCalculatableVAS,
  } = validateProduct(state)

  const supplyChainServices = calculateSupplyChainServicesValue({
    weight: Number(state.dimValue[DimensionValueTypes.P]),
    weightUnits: state.weightDropdownValue,
    categoryId: Number(state.category),
  })
  const valueAddServices = state.vas
    ? calculateValueAddedServicesValue({
        weight: Number(state.dimValue[DimensionValueTypes.P]),
        weightUnits: state.weightDropdownValue,
        categoryId: Number(state.category),
      })
    : 0

  const storage = calculateStorage({
    length: Number(state.dimValue[DimensionValueTypes.L]),
    width: Number(state.dimValue[DimensionValueTypes.W]),
    height: Number(state.dimValue[DimensionValueTypes.H]),
  })
  const chosenStorageValue =
    period === PeriodTypes.ninety_days
      ? storage[PeriodTypes.ninety_days]
      : storage[PeriodTypes.ninety_days_peak]

  return (
    <div className="mt-5 flex max-[890px]:flex-col">
      {/* Left Section */}
      <Form className="flex grow flex-col items-start p-12 ring-1 ring-gray-200 min-[890px]:w-1/2 min-[890px]:rounded-l-3xl max-[890px]:rounded-t-3xl">
        <div className="flex">
          <p className="body-text font-uniform-rnd text-nav-item-text">
            Tell us about your product.
          </p>
        </div>
        <Typography.H5 className="mb-3 mt-6">Dimensions</Typography.H5>
        <Flex align="center" className="max-[890px]:flex-col max-[890px]:gap-4 w-full">
          <Form.Item className="mb-0 w-full">
            <InputNumber
              size="large"
              inputMode="decimal"
              style={{ minWidth: '100px' }}
              className="input-number-custom-right w-full"
              value={state.dimValue[DimensionValueTypes.L]}
              addonAfter={<DimensionSuffix>L</DimensionSuffix>}
              onChange={val => onChangeInput(val)(DimensionValueTypes.L)}
            />
          </Form.Item>
          <Form.Item className="mb-0 w-full">
            <InputNumber
              size="large"
              inputMode="decimal"
              style={{ minWidth: '100px' }}
              className="input-number-custom-both w-full"
              value={state.dimValue[DimensionValueTypes.W]}
              addonAfter={<DimensionSuffix>W</DimensionSuffix>}
              onChange={val => onChangeInput(val)(DimensionValueTypes.W)}
            />
          </Form.Item>
          <Form.Item className="mb-0 w-full">
            <InputNumber
              size="large"
              inputMode="decimal"
              style={{ minWidth: '100px' }}
              className="input-number-custom-left w-full"
              value={state.dimValue[DimensionValueTypes.H]}
              addonAfter={<DimensionSuffix>H</DimensionSuffix>}
              onChange={val => onChangeInput(val)(DimensionValueTypes.H)}
            />
          </Form.Item>
          <DimensionSuffix>in.</DimensionSuffix>
        </Flex>
        <ErrorText>{isInitalDimState ? null : dimensionsError}</ErrorText>
        <div className="divider mt-9" />
        <Typography.H5 className="mb-3 mt-6">Weight</Typography.H5>
        <Form.Item>
          <InputNumber
            size="large"
            inputMode="decimal"
            className="w-[200px]"
            value={state.dimValue[DimensionValueTypes.P]}
            addonAfter={
              <Form.Item noStyle name="suffix">
                <Select
                  defaultValue={state.weightDropdownValue}
                  className="w-[100px]"
                  value={state.weightDropdownValue}
                  onChange={onChangeDropdown('weightDropdownValue')}
                >
                  {weightUnits.map(unit => (
                    <Select.Option key={unit.value} value={unit.value}>
                      {unit.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            }
            onChange={value => onChangeInput(value)(DimensionValueTypes.P)}
          />
        </Form.Item>
        <div className="divider mt-9" />
        <Typography.H5 className="mb-3 mt-6">Category</Typography.H5>
        <TreeSelect
          showSearch
          allowClear
          treeDataSimpleMode
          size="large"
          className="w-full"
          treeNodeFilterProp="title"
          value={state.category}
          treeData={categories}
          onChange={category => setState(prev => ({ ...prev, category }))}
        />
        <div className="flex flex-col items-start">
          <Checkbox
            className="mt-3"
            disabled={checkboxesDisabled}
            checked={state.vas}
            onChange={onChangeVASCheckbox}
          >
            Value-Added Services
          </Checkbox>
        </div>
        <ErrorText>{`${vasDimensionsError || ''} ${vasWeightError || ''}`}</ErrorText>
      </Form>

      {/* Right Section */}
      <div className="flex flex-col justify-between bg-primary-navy-dark pt-12 ring-1 ring-gray-900 min-[890px]:w-1/2 min-[890px]:rounded-r-3xl max-[890px]:rounded-b-3xl">
        <div className="flex flex-col px-12">
          <Typography.H5 className="mb-3 text-white">Recurring Fees</Typography.H5>
          <Collapse
            target={
              <div className="flex grow justify-between text-xl text-inherit">
                <div>Storage</div>
                <div className="font-bold">
                  {nonCalculatable ? 'N/A' : `$${chosenStorageValue.toFixed(2)}`}
                </div>
              </div>
            }
          >
            <Radio.Group value={period} className="w-full" onChange={onChangePeriod}>
              <div className="ml-9 mt-3 flex grow flex-col items-start">
                <div className="flex w-full justify-between">
                  <Radio value={PeriodTypes.ninety_days} className="text-white">
                    90 days storage fee
                  </Radio>
                  <div className="text-xl font-bold text-white">
                    {nonCalculatable ? 'N/A' : `$${storage[PeriodTypes.ninety_days].toFixed(2)}`}
                  </div>
                </div>
                <div className="my-2" />
                <div className="flex w-full justify-between">
                  <Radio value={PeriodTypes.ninety_days_peak} className="text-white">
                    90 days peak season storage fee (applicable Oct-Dec)
                  </Radio>
                  <div className="text-xl font-bold text-white">
                    {nonCalculatable
                      ? 'N/A'
                      : `$${storage[PeriodTypes.ninety_days_peak].toFixed(2)}`}
                  </div>
                </div>
              </div>
            </Radio.Group>
          </Collapse>
          <Typography.H5 className="my-3 text-white">One-Time Fees</Typography.H5>
          <div className="flex grow justify-between text-xl text-white py-5 border-t border-b border-grey-800">
            <div>Supply Chain Services</div>
            <div className="font-bold">
              {nonCalculatable ? 'N/A' : `$${supplyChainServices.toFixed(2)}`}
            </div>
          </div>
          <div className="flex grow justify-between text-xl text-white py-5 border-b border-grey-800">
            <div>Value-Added Services</div>
            <div className="font-bold">
              {nonCalculatableVAS ? 'N/A' : `$${valueAddServices.toFixed(2)}`}
            </div>
          </div>

          <div className="mt-3 text-white text-xs">
            For reCommerce, a 10% fee will apply towards each sale&apos;s final price plus any
            applicable marketplace fees. See full list of fees
          </div>
        </div>
        <div className="bg-primary-dark mt-5 flex gap-12 rounded-br-3xl p-12 justify-center">
          <div className="flex flex-col">
            <p className="font-bold text-white">Total one-time fees estimate</p>
            <div className="flex items-center justify-center">
              <Typography.H2 className="text-white">
                {nonCalculatable
                  ? 'N/A'
                  : `$${(
                      (nonCalculatableVAS ? 0 : valueAddServices) +
                      supplyChainServices +
                      chosenStorageValue
                    ).toFixed(2)}`}
              </Typography.H2>
              <p className="ml-3 text-white">per unit</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
