import { Collapse } from 'antd'
import React, { ReactNode } from 'react'
import { platformFeaturesTexts } from 'lib'
import IconCheck from '../assets/icons/icon-check.svg'
import IconBag from '../assets/icons/platform-features/icon-bag.svg'
import IconExchangeArrows from '../assets/icons/platform-features/icon-exchange-arrows.svg'
import IconMail from '../assets/icons/platform-features/icon-mail.svg'
import IconParcelBox from '../assets/icons/platform-features/icon-parcel-box.svg'
import IconShopLocation from '../assets/icons/platform-features/icon-shop-location.svg'
import IconTruck from '../assets/icons/platform-features/icon-truck.svg'
import { Typography } from './Typography'

interface PlatformFeature {
  icon: ReactNode
  featureName: string
  benefits: string[]
}

const PLATFORM_FEATURES: PlatformFeature[] = [
  {
    icon: <IconBag />,
    ...platformFeaturesTexts.marketplaceIntegration,
  },
  {
    icon: <IconExchangeArrows />,
    ...platformFeaturesTexts.flexibleReturns,
  },
  {
    icon: <IconParcelBox />,
    ...platformFeaturesTexts.convenientDropOff,
  },
  {
    icon: <IconTruck />,
    ...platformFeaturesTexts.reverseSupplyChain,
  },
  {
    icon: <IconShopLocation />,
    ...platformFeaturesTexts.reCommerce,
  },
  {
    icon: <IconMail />,
    ...platformFeaturesTexts.notifications,
  },
]

export const PlatformFeatures = () => {
  return (
    <div className="flex flex-col">
      <Collapse
        ghost
        items={[
          {
            headerClass: '!items-center',
            label: <Typography.H5>Platform features</Typography.H5>,
            id: 'platform-features',
            children: (
              <div className="grid grid-cols-3 gap-x-10 gap-y-12 max-sm:grid-cols-1">
                {PLATFORM_FEATURES.map(feature => {
                  return (
                    <div key={feature.featureName} className="flex flex-col gap-2">
                      <div className="flex items-start gap-[0.625rem]">
                        <div>{feature.icon}</div>
                        <Typography.H5>{feature.featureName}</Typography.H5>
                      </div>
                      <ul>
                        {feature.benefits.map(benefit => (
                          <li key={benefit} className="relative flex items-baseline">
                            <IconCheck className="absolute left-0 h-6 w-6" />
                            <Typography.BodyS className="self-start pl-8 text-nav-item-text">
                              {benefit}
                            </Typography.BodyS>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                })}
              </div>
            ),
          },
        ]}
      />
    </div>
  )
}
